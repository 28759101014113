import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import styled from '@emotion/styled'
import DigitalIcon from '../icons/DigitalIcon'
import PublicidadIcon from '../icons/PublicidadIcon'
import DesignIcon from '../icons/DesignIcon'
import useStaticMetadata from '../hooks/useMetadata'

const serviciosData = [
  {
    title: 'Diseño Gráfico',
    subServicios: [
      { title: 'Naming y desarrollo de marca' },
      { title: 'Packaging y Visual Merchandisign' },
      { title: 'Diseño editorial / Infografías' },
    ],
  },
  {
    title: 'Publicidad',
    subServicios: [
      { title: 'Dirección de arte y creación de conceptos' },
      { title: 'Key visuals para campañas ATL / BTL' },
      { title: 'Redacción creativa' },
    ],
  },
  {
    title: 'Digital',
    subServicios: [
      { title: 'UX / UI para websites y productos digitales ' },
      { title: 'Campañas en Social Media' },
      { title: 'Marketing estratégico' },
    ],
  },
]
const getServiceIcon = service => {
  switch (service) {
    case 'Publicidad': {
      return <PublicidadIcon />
    }

    case 'Digital': {
      return <DigitalIcon />
    }
    default: {
      return <DesignIcon />
    }
  }
}

const ServicioWrapper = styled.div`
  border-bottom: 1px solid #646464;
  padding: 0 0 28px;
  margin: 0 0 60px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    border: 0;
    margin: 0 0 70px;
    padding: 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 48px 0 68px;
  }
`
const IconWrapper = styled.div`
  margin: 0 0 27px;
  svg {
    width: 42px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    display: flex;
    align-items: center;
    svg {
      margin-right: 25px;
      width: 24px;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    svg {
      margin-right: 35px;
      width: 30px;
    }
  }
`
const Services = styled.div`
  margin: 100px 0 0;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    margin: 50px 0 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    margin: 120px 0 0;
  }
`
const Line = styled.div`
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    background-color: #646464;
    height: 1px;
    width: 100%;
  }
`
const Title = styled.div`
  font-size: 2.308rem;
  font-weight: bold;
  line-height: 52px;
  margin: 0 0 15px;
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex-basis: 25%;
    font-size: 1.625rem;
    max-width: 160px;
    margin-bottom: 0;
    margin-right: 40px;
    line-height: 32px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    font-size: 2.5rem;
    max-width: 282px;
    line-height: 52px;
  }
`
const Body = styled.div`
  p {
    font-size: 1.308rem;
    line-height: 30px;
    margin: 0 0 20px;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    align-items: center;
    display: flex;
    justify-content: space-between;
    p {
      font-size: 0.813rem;
      flex-basis: 18%;
      margin: 0;
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    p {
      font-size: 1.063rem;
    }
  }
`

const Servicio = ({ title, subServicios }) => {
  return (
    <ServicioWrapper>
      <IconWrapper>
        {getServiceIcon(title)}
        <Line></Line>
      </IconWrapper>
      <Body>
        <Title>{title} /</Title>
        {subServicios.map((item, idx) => (
          <p key={idx}>{item.title}</p>
        ))}
      </Body>
    </ServicioWrapper>
  )
}

const Servicios = ({ data }) => {
  const { servicios } = useStaticMetadata()
  return (
    <Layout>
      <SEO
        title={servicios.title}
        description={servicios.description}
        image="/images/meta-servicios.jpg"
      />
      <Container>
        <Services>
          {serviciosData.map((servicio, idx) => (
            <Servicio key={idx} {...servicio} />
          ))}
        </Services>
      </Container>
    </Layout>
  )
}

export default Servicios
